.hidden {
    display: none;
}

.sidenav-element--selected {
    background-color: #bcbcbc;
}

.sidenav {
    overflow-y: scroll;
}