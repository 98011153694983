:root {
    --bg-color-gray: #F1F3F4;
    --hover-color: lightyellow;
}

.sidenav {
  height:100vh;
  background-color: var(--bg-color-gray);
  display: inline-block;
}

.sidenav * {
  cursor: pointer;
}

#title {
  text-align: left;
  padding: 0 10px;
}

.topnav {
  min-height:32px;
  width: 100vw;
  background-color: lightGray;
  display: inline-block;
}

.topnav * {
  cursor: pointer;
}

.sidenav-element{
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px;
}

.sidenav-element span {
  padding: 0px 10px;
}

.sidenav-title {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.App-button {
  border: 0;
  display: block;
  width: 100%;
  background: green;
  color: white;
  font-size: 1.2em;
  padding: 0.5em;
}

.App-viewer {
  position: relative;
  flex: 1;
}
